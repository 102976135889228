import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {actionCreators as documentActions} from '../store/DocumentStore';
import config from '../config';
import Menu from './Menu';
import './Documents.css';

const Documents = props => {
    const documents = props.documentStore.documents;
    const activeDocument = props.documentStore.activeDocument;

    return(
        <div className="App">
            <Menu />
            <div className='documents'>
                <div className='documents-container'>
                    {documents && documents.map((d,i) => {
                        return(
                            <div key={i} onClick={() => props.documentActions.setActive(d)} className={`document ${activeDocument && d.id === activeDocument.id ? 'active' : ''}`}>
                                {d.name}
                            </div>
                        );
                    })}
                </div>
                {activeDocument &&
                <div className='preview'>
                    <embed key={`${config.server}${activeDocument.path}`} src={`${config.server}${activeDocument.path}`} alt="pdf" pluginspage="http://www.adobe.com/products/acrobat/readstep2.html" />
                </div>
                }
            </div>
        </div>
    );
};

export default connect(
    state => ({
        documentStore: state.documentStore,
    }),
    dispatch => ({
        documentActions: bindActionCreators(documentActions, dispatch)
    })
)(Documents);