import {actionCreators as appActions} from "./AppStore";
import {actionCreators as routerActions} from "./RouterActions";

export const reducerName = 'auth';

const AUTH_SET_ORGANIZATION_ID = 'AUTH_SET_ORGANIZATION_ID';
const AUTH_SET_API_KEY = 'AUTH_SET_API_KEY';

const orgId = localStorage.getItem('orgId');
const apiKey = localStorage.getItem('apiKey');

const initialState = {
    orgId: orgId,
    apiKey: apiKey
};

export const actionCreators = {
    setOrgId: (id) => async(dispatch, getState) => {
        dispatch({type: AUTH_SET_ORGANIZATION_ID, id});
    },
    setApiKey: (apiKey) => async(dispatch, getState) => {
        dispatch({type: AUTH_SET_API_KEY, apiKey})
    },
    login: () => async(dispatch, getState) => {
        dispatch(appActions.refresh());
        dispatch(routerActions.navigate('/'));
    }
};

const reducerMethods = {
    AUTH_SET_ORGANIZATION_ID: (state, action) => {
        localStorage.setItem('orgId', action.id);
        return{
            ...state,
            orgId: action.id
        };
    },
    AUTH_SET_API_KEY: (state, action) => {
        localStorage.setItem('apiKey', action.apiKey);
        return{
            ...state,
            apiKey: action.apiKey
        }
    }
};

export const reducer = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action) || state;
    return state;
};