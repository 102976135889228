import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

const dayNames = ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør'];

export const Day = props => {

    const style = {
        width: props.size + '%'
    };
    let className;
    const weekDay = props.day.day();
    if(moment().isSame(props.day, 'date')){
        className= 'dd-day today';
    }
    else if(weekDay === 6 || weekDay === 0){
        className= 'dd-day weekend';
    }
    else{
        className = 'dd-day'
    }

    return (
        <div className={className} key={props.day.toString()} style={style} onClick={() => {props.onClick(props.day)}}>
            <div>{dayNames[weekDay]}</div>
            <div>{props.day.date()}</div>
        </div>
    );
};
Day.propTypes = {
    day: PropTypes.object.isRequired,
    size: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired
};



export const EmptyDay = props => {
    return (
        <div className={`dd-cell empty${props.highlight ? ' hightlight' : ''}`} style={{width: props.size+'%'}}/>
    );
};
EmptyDay.propTypes = {
    size: PropTypes.number.isRequired,
    highlight: PropTypes.bool.isRequired
};

export const ColorDay = props => {

    let style = {width: props.size+'%'};
    if(props.pct === 100){
        style = {
            ...style,
            background: props.hexColor
        }
    }
    else if(props.fromLeft){
        style = {
            ...style,
            background: `linear-gradient(90deg, rgba(255,255,255,0.3) ${props.pct}%, ${props.hexColor} ${props.pct}%)`
        }

    }
    else{
        style = {
            ...style,
            background: `linear-gradient(90deg, ${props.hexColor} ${props.pct}% , rgba(255,255,255,0.3) ${props.pct}%)`
        }
    }

    return (
        <div className={`dd-cell${props.highlight ? ' hightlight' : ''}`} key={props.day.toString()} style={style}/>
    );
};
ColorDay.propTypes = {
    day: PropTypes.object.isRequired,
    size: PropTypes.number.isRequired,
    highlight: PropTypes.bool.isRequired,
    hexColor: PropTypes.string.isRequired,
    pct: PropTypes.number.isRequired,
    fromLeft: PropTypes.bool.isRequired
};
