import React from 'react';
import {connect} from 'react-redux';
import * as EventStore from '../../store/EventStore';
import { bindActionCreators } from 'redux';
import Menu from '../Menu';
import './EventOverview.css';
import Event from './Event';

const EventOverview = props => {

    const events = props.eventState.events.map(e => {
        const showInvitees = props.eventState.showInviteesForEvent === e.id;
        return(
            <Event
                key={e.id}
                event={e}
                showInvitees={showInvitees}
                onClick={() => props.eventActions.showInviteesForEvent(!showInvitees, e.id)}
            />
        )
    });

    return(
        <div className="App">
            <Menu />
            <div className='event-overview'>
                <div className='events'>
                    {events}
                </div>
            </div>
        </div>
    )
};

export default connect(
    state => ({
        eventState: state[EventStore.reducerName]
    }),
    dispatch => ({
        eventActions: bindActionCreators(EventStore.actionCreators, dispatch)
    })
)(EventOverview);