import axios from 'axios';
import config from '../config';
import {createAuth} from '../Helpers';
import {actionCreators as routerActions} from './RouterActions';

export const reducerName = 'personState';

const PERSON_GET_RESPONSE = 'PERSON_GET_RESPONSE';
const PERSON_GETALL_RESPONSE = 'PERSON_GETALL_RESPONSE';

const PERSON_CHECK_IN_RESPONSE = 'PERSON_CHECK_IN_RESPONSE';
const PERSON_CHECK_OUT_RESPONSE = 'PERSON_CHECK_OUT_RESPONSE';

const PERSON_SHOW_RADIAL = 'PERSON_SHOW_RADIAL';

const initialState = {
    persons: {},
};

export const actionCreators = {

    getPerson: (personId) => async(dispatch, getState) => {
        axios({
            method: 'GET',
            url:`${config.server}/api/person/${personId}`,
            withCredentials: true,
            auth: createAuth(getState)
        }).then(response => {
            const data = response.data;
            dispatch({type: PERSON_GET_RESPONSE, data});
        }).catch(error =>{

        });
    },
    getAllPersons: () => async(dispatch, getState) => {
        axios({
            method: 'GET',
            url: `${config.server}/api/person`,
            withCredentials: true,
            auth: createAuth(getState)
        }).then(response => {
            const data = response.data;
            const persons = data.reduce((obj, item) => {obj[item.id] = item; return obj}, {});
            dispatch({type: PERSON_GETALL_RESPONSE, data: persons});
        }).catch(error => {
            if(error.response.status === 401 || error.response.status === 404){
                dispatch(routerActions.navigate('/login'));
            }
        });
    },
    checkIn: (personId) => async(dispatch, getState) => {

        axios({
            method: 'POST',
            url:`${config.server}/api/person/checkin/${personId}`,
            withCredentials: true,
            auth: createAuth(getState)
        }).then(response => {
            dispatch({type: PERSON_CHECK_IN_RESPONSE, person:response.data});
        }).catch(error => {

        });
    },
    checkOut: (personId) => async(dispatch, getState) => {
        axios({
            method: 'POST',
            url:`${config.server}/api/person/checkout/${personId}`,
            withCredentials: true,
            auth: createAuth(getState)
        }).then(response => {
            dispatch({type: PERSON_CHECK_OUT_RESPONSE, person:response.data});
        }).catch(error =>{

        });
    },
    showRadial: (personId, show) => async(dispatch, getState) => {
        dispatch({type: PERSON_SHOW_RADIAL, personId: personId, show: show});
    }
};

const reducerMethods = {
    PERSON_SHOW_RADIAL: (state, action) => {
        const personId = action.personId;
        const show = action.show;
        return{
            ...state,
            persons:{
                ...state.persons,
                [personId] :{
                    ...state.persons[personId],
                    showRadial: show
                }
            }
        }
    },
    PERSON_GETALL_RESPONSE: (state, action) => {
        return{
            ...state,
            persons: action.data,
        }
    },
    PERSON_GET_RESPONSE: (state, action) => {
        return{
            ...state,
            persons:{
                ...state.persons,
                [action.data.id]: action.data,
            }
        }
    },
    PERSON_CHECK_IN_RESPONSE: (state, action) => {
        return{
            ...state,
            persons:{
                ...state.persons,
                [action.person.id]: action.person
            }
        }
    },
    PERSON_CHECK_OUT_RESPONSE: (state, action) => {
        return{
            ...state,
            persons:{
                ...state.persons,
                [action.person.id]: action.person
            }
        }
    }
};

export const reducer = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};