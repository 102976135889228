import React from 'react';
import Slider from 'react-slick';

import drLogoImage from '../../images/dr_logo.png';
import drLogo2Image from '../../images/dr_logo4.png';
import './DrNews.css';
import {connect} from "react-redux";

const DrNews = props => {

    const renderSlide = (n) => {

        const articleImg = n['DR:XmlImageArticle'];
        const imgUriContainer = articleImg && articleImg['DR:ImageUri620x349'];
        const articleImgUrl = imgUriContainer && imgUriContainer['#cdata-section'];

        return (
            <div key={n.guid['#cdata-section']||n.guid['#text']} className="news-slide-dr clearfix">
                <h3>{n.title['#cdata-section']}</h3>
                <div className='clearfix'/>
                <img className='article-img' src={articleImgUrl || drLogo2Image} alt='' />
                <p>{!(n.description) || n.description['#cdata-section']}</p>                    
                <img src={drLogoImage} className='logo' alt='' />
            </div>
        );
    };

    const slideComponents = props.state.drNewsState.news.map((n) => renderSlide(n));

    const settings = {
        autoplay : true,
        swipeToSlide: true,
        speed: 300,
        autoplaySpeed: 10000,
        arrows: false,
    };

    return(
        <div className="temp-slide">
            <Slider {...settings}>
               {slideComponents}
            </Slider>
        </div>
    );

};

export default connect(
    state => ({state: state})
)(DrNews);