import axios from 'axios';
import config from '../config';
import {createAuth} from "../Helpers";

const TV2NEWS_GET_RESPONSE = 'TV2NEWS_GET_RESPONSE';

const initialState = {
    news: []
};

export const actionCreators = {
    getNews: () => async(dispatch, getState) => {
        axios({
            method: 'GET',
            url: `${config.server}/api/adminOptions/tv2`,
            withCredentials: true,
            auth: createAuth(getState)
        }).then(response => {
            const data = response.data.rss.channel.item;
            dispatch({type: TV2NEWS_GET_RESPONSE, data: data});
        }).catch(error => {

        });
    }
};

const reducerMethods = {
    TV2NEWS_GET_RESPONSE: (state, action) => {
        return{
            ...state,
            news: action.data
        }
    }
};

export const reducer = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};