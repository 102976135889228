import React from 'react';
import {actionCreators as plannedItemActions} from '../../store/PlannedItemStore';
import connect from 'react-redux/es/connect/connect';
import {bindActionCreators} from 'redux';

const ColorLegend = props => {
    const components = props.plannedItemState.colorCodes.map((cc) => {
        const style = {color: cc.hexColor}
        return (<li className='legend' key={cc.name} style={style}><p>{cc.name}</p></li>)
    });

    return (
        <ul className="legend-container">
            {components}
        </ul>
    );
};

export default connect(
    state => ({plannedItemState: state.plannedItemState}),
    dispatch => ({
        plannedItemActions: bindActionCreators(plannedItemActions, dispatch)
    })
)(ColorLegend);